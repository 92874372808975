import styled from 'styled-components';
import { sizes } from '../../../styles/variables';
import img from '../../../images/landing_background.png';

export const LandingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${img});
  background-size: cover;
`;

export const LandingContent = styled.div`
  max-width: 1000px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;

  @media (max-width: ${sizes.smMax}) {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  @media (min-width: ${sizes.mdMin}) and (max-width: ${sizes.mdLgMin}) {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 20%;
    padding-right: 20%;
  }

  @media (min-width: ${sizes.mdLgMin}) {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15%;
  }
`;

export const LandingButtons = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 3rem 1rem;
  text-align: center;
`;
