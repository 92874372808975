/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, Link } from 'gatsby';

import { Row, Column } from '../Common/styled/Groups';
import { LandingButtons } from '../Common/styled/Landing';
import { Spacer } from '../Common/styled/Spacer';
import { FillButton } from '../Common/styled/Button';
import landingLogo from '../../images/landing_logo.png';

export const LandingView = () => {
  const { t } = useTranslation();

  return (
    <div className="my-auto">
      <Row className="mb-4">
        <Column style={{ flex: '1.75' }}>
          <div>
            <img className="w-100" src={landingLogo} alt="landing_logo" />
            <h1>{t('landing.message')}</h1>
          </div>
        </Column>
        <Spacer customMargin="4%" />
        <Column>
          <LandingButtons>
            <FillButton className="mx-auto my-0 rounded" type="button" style={{ height: '50px' }} onClick={() => navigate('/dashboard')}>
              <big><b>{t('log.in')}</b></big>
            </FillButton>
            <Link to="/dashboard">
              <p className="mb-0">{`${t('forgot.password')} ?`}</p>
            </Link>
            <hr className="my-4" style={{ width: '80%' }} />
            <FillButton backgroundColor="#002145" className="d-block mx-auto mt-3 mb-0 rounded" type="button" style={{ height: '50px' }} onClick={() => navigate('/register')}>
              <big>
                <b>{t('sing.up')} </b>
                {t('for.free')}
              </big>
            </FillButton>
            <Link to="/plans/public" style={{ color: 'black' }}>
              <p className="mb-0">{`${t('see.plans.and.pricing')} >`}</p>
            </Link>
          </LandingButtons>
        </Column>
      </Row>
    </div>
  );
};
