import React from 'react';
import '../../i18n';

import { LandingContainer, LandingContent } from '../../components/Common/styled/Landing';
import { LandingView } from '../../components/Landing/LandingView';

import Seo from '../../components/Seo';
import { AppContainer } from '../../components/Common/styled/AppContainer';
import GlobalStyle from '../../themes/GlobalStyle';
import GlobalThemeStyles from '../../themes/GlobalThemeStyles';

export default () => (
  <AppContainer>
    <Seo />
    <GlobalStyle />
    <GlobalThemeStyles />
    <LandingContainer>
      <LandingContent>
        <LandingView />
      </LandingContent>
    </LandingContainer>
  </AppContainer>
);
